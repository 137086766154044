import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'
import { apolloClient } from '@/apollo'

import SHORTEN_PMENUS_REQUEST from '@/gql/mutation/shortenPmenusRequest.gql'

export default {
  namespaced: true,
  state: {
    isInfo: {
      menu: {
        images: false,
        content: false,
        schedules: false,
      },
      item: {
        youtubeLinks: false,
        images: false,
        extras: false,
        suggestions: false,
        tags: false,
      },
      combination: {
        consist: false,
        youtubeLinks: false,
        images: false,
        suggestions: false,
        tags: false,
      },
      zone: {
        tables: false,
        menus: false,
      },
      event: {
        details: false,
        schedules: false,
      },
      users: {
        description: false,
      },
    },
    locale: '',
    navHistory: {
      menu: [],
      item: [],
      zone: [],
      event: [],
    },
    invitationCode: '',
    isSubscriptionNotification: true,
    countryRegistration: null,
    forceLogoutCounter: 0,
    notificationsChecked: [],
  },
  getters: {
    isInfo(state) {
      return state.isInfo
    },
    locale(state) {
      return state.locale
    },
    navHistory(state) {
      return state.navHistory
    },
    invitationCode(state) {
      return state.invitationCode
    },
    isSubscriptionNotification(state) {
      return state.isSubscriptionNotification
    },
    countryRegistration(state) {
      return state.countryRegistration
    },
    forceLogoutCounter(state) {
      return state.forceLogoutCounter
    },
    VUE_APP_IMG_BASE() {
      return process.env.VUE_APP_IMG_BASE
    },
    notificationsChecked(state) {
      return state.notificationsChecked
    },
  },
  mutations: {
    setIsInfo(state, data) {
      state.isInfo = data
    },
    setLocale(state, data) {
      state.locale = data
    },
    setNavHistory(state, data) {
      state.navHistory = data
    },
    setInvitationCode(state, data) {
      state.invitationCode = data
    },
    setIsSubscriptionNotification(state, data) {
      state.isSubscriptionNotification = data
    },
    setCountryRegistration(state, data) {
      state.countryRegistration = data
    },
    setForceLogoutCounter(state, data) {
      state.forceLogoutCounter = data
    },
    setNotificationsChecked(state, data) {
      state.notificationsChecked = data
    },
  },
  actions: {
    setIsInfo({ commit, getters }, [page, type]) {
      commit('setIsInfo', {
        ...getters.isInfo,
        [page]: {
          ...getters.isInfo[page],
          [type]: !getters.isInfo[page][type],
        },
      })
    },
    setLocale({ commit }, data) {
      commit('setLocale', data)
    },
    setNavHistory({ commit, getters }, data) {
      commit('setNavHistory', {
        ...getters.navHistory,
        [data[0]]: [...new Set([data[1], ...getters.navHistory[data[0]]])].slice(0, 4),
      })
    },
    setInvitationCode({ commit }, data) {
      commit('setInvitationCode', data)
    },
    setIsSubscriptionNotification({ commit }, data) {
      commit('setIsSubscriptionNotification', data)
    },
    setCountryRegistration({ commit }, data) {
      commit('setCountryRegistration', data)
    },

    clearLocalStorageData() {
      localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName)
      localStorage.removeItem(jwtDefaultConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
    },
    clearStoreMainData({ dispatch }) {
      dispatch('schedules/clearSchedulesData', null, { root: true })
      dispatch('floorPlan/clearFloorPlanData', null, { root: true })
      dispatch('menuManagement/clearMenuManagementData', null, { root: true })
    },
    clearStoreData({ dispatch }) {
      dispatch('clearStoreMainData')

      dispatch('billing/clearBillingData', null, { root: true })
      dispatch('restaurant/clearRestaurantData', null, { root: true })
      dispatch('organizations/clearOrganizationsData', null, { root: true })
    },

    async shortenPmenusRequest(context, [restaurantId, zoneId, tableId]) {
      const variables = {
        restaurantId,
        zoneId,
      }

      if (tableId) {
        variables.tableId = tableId
      }

      const response = await apolloClient.mutate({
        mutation: SHORTEN_PMENUS_REQUEST,
        variables,
      })

      return response.data.shortenPmenusRequest.code
    },
    setForceLogoutCounter({ commit }, data) {
      commit('setForceLogoutCounter', data)
    },
    setNotificationsChecked({ commit }, data) {
      commit('setNotificationsChecked', data)
    },
  },
}
